import React, { Component } from 'react';

import { ExternalLink, Location } from '../_common/Icons';
import { getCmsValue } from '../../utils/utils';

class StoreLocatorList extends Component {
  constructor(props) {
    super(props);
    this.listRef = React.createRef();
    this.listItemRefs = {};
  }

  componentDidUpdate() {
    this.props.setListRefs(this.listRef, this.listItemRefs);
  }

  render() {
    const { site } = this.props;

    const visibleDealers = this.props.visibleDealers.map((dealer, i) => {
      const dealerId = dealer.id.toString();
      const activeDealerId = this.props.activeDealerId
        ? this.props.activeDealerId.toString()
        : '';

      return (
        <li
          key={i}
          className={`store-locator__item store-locator-item ${
            dealerId === activeDealerId ? 'active' : ''
          }`}
          data-id={dealer.id}
          ref={(instance) => (this.listItemRefs[dealer.id] = instance)}
        >
          <div className="store-locator-item__content">
            <h5 className="store-locator-item__title">
              {dealer.name}
              {dealer.type === 'distributor' ? ' (Distributor)' : ''}
            </h5>
            <div className="store-locator-item__address">
              <a
                href={`https://maps.google.com/?q=${dealer.address}+${dealer.area}+${dealer.region}+${dealer.zip}`}
                rel="noopener noreferrer"
                target="_blank"
              >
                <div className="store-locator-item__address__link">
                  <span>{dealer.address}</span>
                  <br />
                  <span>
                    {dealer.area}, {dealer.region} {dealer.zip}
                  </span>
                </div>
                {ExternalLink}
              </a>
            </div>
            <div className="store-locator-item__description">
              <a
                href={dealer.website?.replace('www.', '//')}
                rel="noopener norefferer"
                target="_blank"
              >
                {dealer.website || ''}
              </a>
            </div>
            <div className="store-locator-item__phone">
              <a href={`tel:${dealer.phone}`}>{dealer.phone}</a>
            </div>
            {dealer.distance ? (
              <div className="store-locator-item__distance">
                <span className="icon">{Location}</span>
                <span>
                  {parseInt((dealer.distance / 1000) * 0.621371, 10)}{' '}
                  {getCmsValue(site.strings.storeLocator__distance, site)}
                </span>
              </div>
            ) : (
              ''
            )}
          </div>
        </li>
      );
    });

    const userLocData = this.props.userLocation.data;

    return (
      <div
        className={`store-locator__list ${
          this.props.listActive ? 'active' : ''
        }`}
        ref={this.listRef}
      >
        <div className="store-locator__list__user-location user-location">
          {userLocData ? (
            <>
              <h4>
                {getCmsValue(site.strings.storeLocator__yourLocationTitle)}
              </h4>
              <h5>
                {userLocData.locality}, {userLocData.principalSubdivision},{' '}
                {userLocData.countryName}
              </h5>
            </>
          ) : (
            <h4>{getCmsValue(site.strings.storeLocator__noLocation)}</h4>
          )}
        </div>
        {this.props.visibleDealers.length > 0 ? (
          <ul
            className={`${this.props.activeDealerId ? 'has-active-items' : ''}`}
          >
            {visibleDealers}
          </ul>
        ) : (
          <div className="store-locator__list-no-dealers">
            <ul>
              <li className="store-locator__item store-locator-item">
                <div className="store-locator-item__content">
                  <h5 className="store-locator-item__title">
                    {this.props.dealersAvailable > 0
                      ? getCmsValue(
                          site.strings.storeLocator__dealersAvailable,
                          site
                        )
                      : getCmsValue(
                          site.strings.storeLocator__noDealersAvailable,
                          site
                        )}
                  </h5>
                  <div className="store-locator-item__description">
                    {this.props.dealersAvailable > 0
                      ? getCmsValue(site.strings.storeLocator__zoomIn, site)
                      : ``}
                  </div>
                </div>
              </li>
            </ul>
          </div>
        )}
        <div className="store-locator__store-request">
          <button
            className="store-locator__store-request-button"
            onClick={this.props.setStoreRequestOpen}
          >
            Add your shop
          </button>
        </div>
      </div>
    );
  }
}

export default StoreLocatorList;
